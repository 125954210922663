export const seo = {
  url: 'firma',
  title: {
    pl: 'Firma spedycyjna najwyższej rangi',
    en: 'Top rank forwarding company',
    ua: 'Транспортно-експедиційна компанія вищого рангу',
  },
  desc: {
    pl: `Wspólnie budujemy przewagę na rynku. Poznaj firmę z polskim kapitałem, europejskim duchem i globalnym zasięgiem.`,
    en: `Meet a company with Polish capital, European spirit and Global reach. Together, we build an advantage on the market.`,
    ua: `Разом ми створюємо перевагу на ринку. Познайомтеся з компанією з польським капіталом, європейським духом і глобальним охопленням.`,
  },
  keywords: [
    'omida',
    'sea and air',
    'sea & air',
    'firma spedycyjna',
    'firma logistyczna',
    'firma transportowa',
  ],
}

export const intro = {
  title: {
    pl: 'Omida Sea & Air',
    en: 'Omida Sea & Air',
    ua: 'Omida Sea & Air',
  },
  desc: {
    pl: `Wspólnie budujemy przewagę na rynku. Poznaj firmę z polskim kapitałem, europejskim duchem i globalnym zasięgiem.`,
    en: `Meet a company with Polish capital, European spirit and Global reach. Together, we build an advantage on the market.`,
    ua: `Разом ми створюємо перевагу на ринку. Познайомтеся з компанією з польським капіталом, європейським духом і глобальним охопленням.`,
  },
}

export const main = {
  title: {
    pl: 'Odkryj Sea & Air - spedycję z ponad 10-letnim doświadczeniem w TSL',
    en: 'Discover Sea & Air - forwarding company with over 10 years of experience in TSL',
    ua: 'Відкрийте Sea & Air - експедитор з більш ніж 10-річним досвідом роботи в TSL',
  },
  desc: {
    pl: 'Realizujemy transport, który łączy cały świat. Każdego roku finalizujemy tysiące przewozów i przesyłek, zapewniając pełną obsługę logistyczną i spedycyjną.  Doskonalimy nasze umiejętności, poszerzamy wiedzę i zdobywamy nowe doświadczenie. Zapewniamy transport morski, lotniczy oraz kolejowy, zatrudniając specjalistów w swoich dziedzinach.',
    en: 'We provide transport that connects the whole world. Every year, we finalize thousands of transports and shipments, providing full logistic and forwarding services. We improve our skills, broaden our knowledge and gain new experience. We provide sea, air and rail transport, employing specialists in their fields.',
    ua: 'Ми надаємо транспорт, який зєднує весь світ. Щороку ми завершуємо тисячі перевезень і відправлень, надаючи повні логістичні та експедиторські послуги. Ми вдосконалюємо свої навички, розширюємо знання та отримуємо новий досвід. Ми здійснюємо морські, авіаційні та залізничні перевезення, залучаючи фахівців у своїй галузі.',
  },
}
