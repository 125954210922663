import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { seo, intro, main } from '../../content/firma'
import { useLangContext } from '../../context/lang.context'
import Intro from '../../components/Intro'
import Main from '../../components/Main'
import LinkTile from '../../components/LinkTile'
import { sLinkTiles } from '../../style'

const Firma = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/firma.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      uslugi: file(absolutePath: { regex: "/images/intros/uslugi.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      onas: file(absolutePath: { regex: "/images/intros/o-nas.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      prezentacja: file(
        absolutePath: { regex: "/images/intros/prezentacja.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      omidagroup: file(
        absolutePath: { regex: "/images/intros/omida-group.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      osiagniecia: file(
        absolutePath: { regex: "/images/intros/osiagniecia.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      certyfikaty: file(
        absolutePath: { regex: "/images/intros/certyfikaty.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      wspieramy: file(
        absolutePath: { regex: "/images/intros/wspieramy.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      dlamediow: file(
        absolutePath: { regex: "/images/intros/dla-mediow.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      dlaakcjonariuszy: file(
        absolutePath: { regex: "/images/intros/dla-akcjonariuszy.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      ochronadanych: file(
        absolutePath: { regex: "/images/intros/ochrona-danych.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Poznaj Sea & Air',
      en: 'Get to know Sea & Air',
      ua: 'ПІЗНАЙТЕ SEA & AIR',
    },
    action: 'SCROLL',
  }

  const links = [
    {
      slug: '/firma/o-nas/',
      title: {
        pl: 'O nas',
        en: 'About us',
        ua: 'Про нас',
      },
      subtitle: {
        pl: 'Firma',
        en: 'Company',
        ua: 'БІЗНЕС',
      },
      image: query.onas.childImageSharp,
    },
    {
      slug: '/uslugi/',
      title: {
        pl: 'Usługi',
        en: 'Services',
        ua: 'ПОСЛУГИ',
      },
      subtitle: {
        pl: 'Firma',
        en: 'Company',
        ua: 'БІЗНЕС',
      },
      image: query.uslugi.childImageSharp,
    },
    {
      slug: '/pdf/PrezentacjaOmidaSeaAndAirPL.pdf',
      title: {
        pl: 'Prezentacja Firmy',
        en: 'Company Presentation',
        ua: 'Презентація компанії',
      },
      subtitle: {
        pl: 'Firma',
        en: 'Company',
        ua: 'БІЗНЕС',
      },
      image: query.prezentacja.childImageSharp,
    },
    {
      slug: '/firma/osiagniecia/',
      title: {
        pl: 'Osiągnięcia',
        en: 'Achievements',
        ua: 'Досягнення',
      },
      subtitle: {
        pl: 'Firma',
        en: 'Company',
        ua: 'БІЗНЕС',
      },
      image: query.osiagniecia.childImageSharp,
    },
    {
      slug: '/firma/certyfikaty/',
      title: {
        pl: 'Certyfikaty',
        en: 'Certificates',
        ua: 'Сертифікати',
      },
      subtitle: {
        pl: 'Firma',
        en: 'Company',
        ua: 'БІЗНЕС',
      },
      image: query.certyfikaty.childImageSharp,
    },
    {
      slug: '/firma/wspieramy/',
      title: {
        pl: 'Wspieramy',
        en: 'Supporting',
        ua: 'Ми підтримуємо',
      },
      subtitle: {
        pl: 'Firma',
        en: 'Company',
        ua: 'БІЗНЕС',
      },
      image: query.wspieramy.childImageSharp,
    },
    {
      slug: '/firma/omida-group/',
      title: {
        pl: 'Omida Group',
        en: 'Omida Group',
        ua: 'Omida Group',
      },
      subtitle: {
        pl: 'Firma',
        en: 'Company',
        ua: 'БІЗНЕС'
      },
      image: query.omidagroup.childImageSharp,
    },
    {
      slug: '/firma/dla-mediow/',
      title: {
        pl: 'Dla Mediów',
        en: 'For Media',
        ua: 'Для ЗМІ',
      },
      subtitle: {
        pl: 'Firma',
        en: 'Company',
        ua: 'БІЗНЕС',
      },
      image: query.dlamediow.childImageSharp,
    },
    {
      slug: '/firma/dla-akcjonariuszy/',
      title: {
        pl: 'Dla Akcjonariuszy',
        en: 'For Stakeholders',
        ua: 'Для акціонерів',
      },
      subtitle: {
        pl: 'Firma',
        en: 'Company',
        ua: 'БІЗНЕС',
      },
      image: query.dlaakcjonariuszy.childImageSharp,
    },
    {
      slug: '/firma/ochrona-danych/',
      title: {
        pl: 'Ochrona Danych',
        en: 'Data Protection',
        ua: 'Захист даних',
      },
      subtitle: {
        pl: 'Firma',
        en: 'Company',
        ua: 'БІЗНЕС',
      },
      image: query.ochronadanych.childImageSharp,
    },
  ].filter(
    (link) =>
    !(
      (lang === 'en' &&
      (link.slug === '/firma/dla-akcjonariuszy/') ||

      (lang === 'ua' &&
      (link.slug === '/firma/dla-akcjonariuszy/' ||
        link.slug === '/firma/o-nas/' ||
        link.slug === '/firma/osiagniecia/' ||
        link.slug === '/pdf/PrezentacjaOmidaSeaAndAirPL.pdf' ||
        link.slug === '/firma/wspieramy/' ||
        link.slug === '/firma/dla-mediow/' ||
        link.slug === '/klient/faq/')
      )
    )
  )
  )

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro
        data={{ ...intro, button }}
        image={query.image.childImageSharp}
        position='bottom'
      />
      <Main h={1} title={main.title[lang]} desc={main.desc[lang]}>
        <div css={sLinkTiles}>
          {links.map((item, id) => (
            <LinkTile key={id} data={item} />
          ))}
        </div>
      </Main>
    </Layout>
  )
}

export default Firma
